@import-normalize;

/*
=============== 
Variables
===============
*/

:root {
  --bs-nav-link-font-size: 1.8rem;
  
}

  /*
=============== 
Global Styles
===============
*/

* {
  font-family: 'Alegreya Sans SC';
}

html {
  font-size: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.display-flex {
  display: flex;
}

.flex-direction-row {
  display: flex;
  flex-direction: row;
}

.flex-direction-column {
  display: flex;  
  flex-direction: column;
}

.row-padding {
  padding: 25px 0;
}

/* Custom Styling */

.logo-wrapper {
  display: flex;
  justify-content: center;
}

.main-logo-row {
  justify-content: center;
}

.main-logo {
  max-width: 60%!important;
}

.main-logo img {
  width: 100%;
}

.main-nav {
  width: 80%;
  justify-content: space-evenly;
  margin: 0 auto;
}

.navbar {
  margin-bottom: 50px;
}

.nav {
  justify-content: space-around;
  max-width: 80%;
  margin: 0 auto;
}

.main-nav {
  padding: 25px 0;
}

.main-nav .nav-link {
  color: #000;
  text-transform: uppercase;
}

.main-nav .nav-link:hover {
  color: #c17d65;
}

.navbar-toggler:hover .navbar-toggler-icon {
  background-size: 110%;
}

.navbar-toggler:not(.collapsed) .navbar-toggler-icon {
  background-image: url('../public/images/x-icon.svg')
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 0.02rem!important;
}

/* Mobile Hamburger Menu */
@media screen and (max-width: 992px) {

  button.navbar-toggler {
    background-color: #fff;
    position: fixed;
    top: 10px;
    z-index: 99;
  }

  .main-nav {
    position: relative;
    top: 50px;
  }
  
  #responsive-navbar-nav {
    position: fixed;
    left: 0px;
    top: 0;
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: 100%;
    z-index: 98;
    transition: none 0s ease 0s;
  }

}